import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import Layout from "../../components/UI/Layout"
import Navbar from "../../components/UI/Navbar"
import Seo from "../../components/Seo"
import ServicePage from "../../components/templates/ServicePage"

const query = graphql`
  {
    allContentfulInstallationImages(
      filter: {
        metadata: {
          tags: { elemMatch: { contentful_id: { eq: "hardscapes" } } }
        }
      }
    ) {
      nodes {
        id
        image {
          gatsbyImageData(placeholder: BLURRED, layout: CONSTRAINED)
          id
          title
        }
      }
    }
  }
`

export default function HardscapesPage() {
  const title = `Hardscape` // Singular (not plural)
  const {
    allContentfulInstallationImages: { nodes: image },
  } = useStaticQuery(query)

  const imageList = image[0].image

  return (
    <Layout>
      <Navbar />
      <Seo
        title={`${title}s`}
        description={`Andreatta Waterscapes has more than 30 years of experience providing ${title} installation services. Call today to start planning your next project.`}
      />

      <ServicePage
        imageList={imageList}
        title={title}
        img={
          <StaticImage
            src="../../assets/images/Stone-Stairway_Hardscapes_Andreatta-Waterscapes,-Grants-Pass-Oregon_compress.jpg"
            quality={90}
            loading="eager"
            style={{
              position: "absolute",
              width: "100%",
              zIndex: 1,
              height: "21rem",
            }}
            objectFit="cover"
            objectPosition="50% 50%"
            alt="Stone Stairway Hardscape Grants Pass Oregon Andreatta Waterscapes"
          />
        }
        tagline={`Hardscape design to complete your outdoor spaces`}
        body={[
          {
            id: 0,
            type: "paragraph",
            content: `Hardscapes pull together your outdoor living spaces. By
integrating hard materials such as stone, pavers, and wood
with the water,
plants, lawn, bark and other living materials that make up your waterscape and
landscaping, we’re
able to bring the finishing touches to your living and
entertaining spaces.`,
          },
          {
            id: 1,
            type: "paragraph",
            content: `If you can envision a complete outdoor space, what would it
look like? Have you dreamed of having an outdoor
kitchen or a beautiful gazebo
to sit under while you entertain your friends and family? How about stone or
block
retaining walls, paver pathways, a patio, a firepit, a stone or wooden
bridge over your koi pond, or a deck to sit and
enjoy the sound of your
waterfall?`,
          },
          {
            id: 2,
            type: "paragraph",
            content: `We can design and build your dream outdoor living space,
whether it’s a new project or adding onto an existing
design. Adding usable
outdoor spaces will complete both the functionality and aesthetics of your
outdoor project.`,
          },
        ]}
      />
    </Layout>
  )
}
